/* The switch - the box around the slider 
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.1em;
  height: 1.5em;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 10px;
}

/* Hide default HTML checkbox 
.switch input {
  opacity: 0;
  padding: 100% ;
  width: 100%;
  height:100%;
  z-index: 55 !important;
}

/* The slider 
.slider {
  --background: #28096b;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  transition: .5s;
  border-radius: 30px;
  z-index: -1;
}
 
 
.slider:before {
  position: absolute;
  content: "";
  height: 1.1em;
  width: 1.1em;
  border-radius: 50%;
  left: 10%;
  bottom: 14%;
  box-shadow: inset 8px -4px 0px 0px #fff000;
  background: var(--background);
  transition: .5s;
}

input:checked + .slider {
  background-color: #a8dadc;
}


input:checked + .slider:before {
  transform: translateX(120%);
  box-shadow: inset 15px -4px 0px 15px #fff000;
}
*/
/* The switch - the box around the slider */
.switch-container {
  position: relative;
  display: inline-block;
  width: 4em;
  margin-top: 8px;
  margin-right: 15px;
  height: 1.8em;
}

/* Hide default HTML checkbox */
.switch-container input {
  opacity: 0;
  padding: 100% ;
  width: 100%;
  height:100%;
  z-index: 55 !important;
}

/* The slider */
.switch-container .slider {
  --background: linear-gradient(to right, #415a77, #18151f);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background);
  transition: background-color 0.5s;
  border-radius: 30px;
  z-index: -1;
}

.switch-container .slider:before {
  position: absolute;
  content: "";
  height: 1.2em;
  width: 1.2em;
  border-radius: 50%;
  left: 10%;
  bottom: 15%;
  box-shadow: inset 15px -4px 0px 15px #f8ea27;
  background: var(--primary-color);
  transition: transform 0.5s, box-shadow 0.5s;
}

.switch-container input:checked + .slider {
  background-color: var(--background);
}

.switch-container input:checked + .slider:before {
  transform: translateX(170%);
  box-shadow: inset 8px -4px 0px 0px #f8f8f4;
}

@media (max-width:900px) {
  .switch-container{
    height: 1.2rem;
    width: 3em;
  }
  .switch-container .slider:before{
    height: .8em;
    width: .8em;
    bottom: 16%;
  }
  .switch-container input:checked + .slider:before{
    transform: translateX(200%);
    box-shadow: inset 8px -4px 0px -2px #f8f8f4;
  }
}